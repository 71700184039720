/** @name 默认十六种颜色 */
export const defaultColors = [
  '#FF3535',
  '#FFC12B',
  '#339DEE',
  '#848BE2',
  '#BA68C8',
  '#9375CD',
  '#7987CB',
  '#64B5F5',
  '#4FC4F7',
  '#4DD1E2',
  '#FEB74B',
  '#E57375',
  '#F06292',
  '#EE9A9B',
  '#F48FB1',
  '#CE93D9',
];

/** @name 生成随机十六进制颜色 */
export function getRandomColor() {
  return `#${`00000${((Math.random() * 0x1000000) << 0).toString(16)}`.substr(
    -6,
  )}`;
}
