import request from '../request';

const serviceName = '/iot-smart-helmet/tagGroup';

/** @name 新增标签组 */
export function addTagGroup(data) {
  return request(`${serviceName}`, {
    method: 'POST',
    body: data,
  });
}
/** @name 获取标签组 */
export function getTagGroup(data) {
  return request(`${serviceName}`, {
    method: 'GET',
    body: data,
  });
}
/** @name 编辑标签组 */
export function editTagGroup(data) {
  return request(`${serviceName}`, {
    method: 'PUT',
    body: data,
  });
}
/** @name 删除标签组 */
export function deleteTagGroup(data) {
  return request(`${serviceName}/${data}`, {
    method: 'DELETE',
  });
}
/** @name 新增标签选项 */
export function addTag(data) {
  return request(`${serviceName}/tag`, {
    method: 'POST',
    body: data,
  });
}
/** @name 编辑标签选项 */
export function editTag(data) {
  return request(`${serviceName}/tag`, {
    method: 'PUT',
    body: data,
  });
}
/** @name 删除标签选项 */
export function deleteTag(data) {
  return request(`${serviceName}/tag/${data}`, {
    method: 'DELETE',
  });
}
