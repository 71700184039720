<template>
  <div :class="$style.wrapper">
    <a-tabs v-model="activeKey" default-active-key="1" @change="callback">
      <a-tab-pane
        :tab="item.tagName"
        v-for="(item, idx) in panes"
        :key="item.pkId"
      >
        <a-form-model>
          <a-form-model-item label="标签组名">
            <div :class="$style.groupWrapper">
              <a-input
                v-model="item.tagName"
                :disabled="trueFlag(idx) ? true : false"
                @blur="changeTagGroupName(item)"
                :maxLength="8"
              />
              <x-icon
                v-if="!trueFlag(idx)"
                type="tc-icon-delete"
                :class="$style.ml10"
                @click="delCalloutGroup(item)"
              />
            </div>
          </a-form-model-item>
          <a-form-model-item label="标签选项" v-if="idx !== 1">
            <a-input-group>
              <div
                :class="$style.inputWrapper"
                v-for="v in item.tags"
                :key="v.pkId"
              >
                <custom-color-picker
                  :class="$style.mr10"
                  v-model="v.tagColor"
                  @change="$e => changeTagColor($e, v)"
                  v-if="idx !== 0"
                />
                <span v-else :class="[$style.colorInfo, $style.action]"></span>
                <a-input
                  v-model="v.tagName"
                  @blur="changeTagName(v)"
                  :disabled="idx === 0 ? true : false"
                  :maxLength="16"
                /><x-icon
                  v-if="idx !== 0"
                  type="tc-icon-delete"
                  :class="$style.ml10"
                  @click="delCallout(v)"
                />
              </div>
            </a-input-group>
          </a-form-model-item>
          <a-form-model-item v-if="trueFlag(idx)" label="图像识别模型">
            <a-select :disabled="true" placeholder="敬请期待！" />
          </a-form-model-item>
          <a-form-model-item v-if="!trueFlag(idx)">
            <a-button
              type="primary"
              size="small"
              icon="plus"
              @click="addCallout(item, idx)"
              >添加选项</a-button
            >
          </a-form-model-item>
        </a-form-model>
      </a-tab-pane>
      <!-- <a-button slot="tabBarExtraContent" icon="plus" @click="addCalloutGroup">
        新增
      </a-button> -->
      <span
        @click="addCalloutGroup"
        slot="tabBarExtraContent"
        :class="$style.addBtnWrap"
        ><a-icon type="plus" :class="$style.mr5" />新增</span
      >
    </a-tabs>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { CustomColorPicker } from '@/components/custom-color-picker';
import {
  getTagGroup,
  addTagGroup,
  editTagGroup,
  deleteTagGroup,
  deleteTag,
  addTag,
  editTag,
} from '@/services/smart-hat/tag-group';

let countList = [];
let groupCount = 0;
@Component({
  components: {
    CustomColorPicker,
  },
})
export default class AddCallout extends Vue {
  activeKey = '1';
  callback() {}

  get colorActive() {
    const bodyCss = getComputedStyle(document.body);
    return bodyCss.getPropertyValue('--font-active')
      ? bodyCss.getPropertyValue('--font-active')
      : '#f99603';
  }

  /** @name 行为识别和人脸识别 */
  trueFlag(idx) {
    return idx === 0 || idx === 1;
  }

  async mounted() {
    await this.getData();
    if (this.panes.length > 1) {
      this.activeKey = this.panes[0].pkId;
    }
    this.calcCount();
  }
  panes = [];
  async getData() {
    const data = await getTagGroup();
    this.panes = data;
  }
  calcCount() {
    this.panes.forEach((item, idx) => {
      if (idx !== 0 || idx !== 1) {
        let count = item.tags.length;
        item.tags.forEach(v => {
          if (v.tagName.includes('自定义标签')) {
            let num = Number(v.tagName.replace(/[^\d]/g, ''));
            if (num > count) {
              count = num;
            }
          }
        });
        countList.push(count);
        if (item.tagName.includes('自定义标签组')) {
          let num = Number(item.tagName.replace(/[^\d]/g, ''));
          if (num > groupCount) {
            groupCount = num;
          }
        }
      }
    });
  }

  async addCalloutGroup() {
    try {
      if (this.panes.length === 52) {
        this.$message.warn('最多限50个分组（含系统组）！');
        return;
      }
      countList.push(1);
      await addTagGroup({
        groupName: `自定义标签组${++groupCount}`,
        tag: {
          tagColor: this.colorActive,
          tagName: '自定义标签1',
        },
      });
      await this.getData();
      this.activeKey = this.panes[this.panes.length - 1].pkId;
    } catch (error) {
      return;
    }
  }
  async delCalloutGroup(item) {
    try {
      await deleteTagGroup(item.pkId);
      this.$message.success('删除成功！');
      await this.getData();
      if (this.panes.length > 0) {
        this.activeKey = this.panes[this.panes.length - 1].pkId;
      }
    } catch (error) {
      return;
    }
  }
  async addCallout(item, index) {
    try {
      if (item.tags && item.tags.length === 100) {
        this.$message.warn('最多限100个标签！');
        return;
      }
      await addTag({
        parentId: item.pkId,
        tagColor: this.colorActive,
        tagName: `自定义标签${++countList[index]}`,
      });
      await this.getData();
    } catch (error) {
      return;
    }
  }
  async delCallout(item) {
    try {
      await deleteTag(item.pkId);
      this.$message.success('删除成功！');
      await this.getData();
    } catch (error) {
      return;
    }
  }

  async changeTagGroupName(item) {
    try {
      await editTagGroup({
        pkId: item.pkId,
        groupName: item.tagName,
      });
      await this.getData();
    } catch (error) {
      return;
    }
  }

  async changeTagColor(color, item) {
    try {
      await editTag({
        pkId: item.pkId,
        tagName: item.tagName,
        tagColor: color,
      });
      await this.getData();
    } catch (error) {
      return;
    }
  }

  async changeTagName(item) {
    try {
      await editTag({
        pkId: item.pkId,
        tagName: item.tagName,
        tagColor: item.tagColor,
      });
      await this.getData();
    } catch (error) {
      return;
    }
  }

  getValue() {
    return true;
  }
}
</script>
<style lang="less" module>
.wrapper {
  :global {
    .ant-input-group .ant-input {
      flex: 1;
      float: none;
    }
    .ant-btn-sm {
      height: 24px;
    }
  }
  .inputWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
    .colorInfo {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      margin-right: 10px;
      &.action {
        background-color: #ff3535;
      }
      &.face {
        background-color: #f99603;
      }
    }
  }
  .mr10 {
    margin-right: 10px;
  }
  .ml10 {
    margin-left: 10px;
  }
  .mr5 {
    margin-right: 5px;
  }
  .groupWrapper {
    display: flex;
    align-items: center;
  }
  .addBtnWrap {
    color: var(--font-active);
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
