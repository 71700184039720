<template>
  <a-dropdown
    :trigger="['click']"
    :overlayClassName="$style.picker"
    :key="value"
    :style="extendStyle"
  >
    <span
      :class="$style.button"
      :style="`background: ${value || defaultColor}`"
    />
    <custom-color-panel
      slot="overlay"
      :value="value"
      @change="handleColorChange"
      :identifier="identifier"
    />
  </a-dropdown>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomColorPanel from './color-panel.vue';

@Component({
  components: { CustomColorPanel },
})
export default class CustomColorPicker extends Vue {
  @Prop({ type: String, default: 'form.design.color.picker' }) identifier;
  @Prop({ type: String, default: '' }) value;
  @Prop({ type: String, default: 'var(--primary)' }) defaultColor;
  @Prop({ type: Boolean, default: false }) borderExtend;

  // 扩展的CSS属性
  get extendStyle() {
    return this.borderExtend ? { border: '2px solid' } : {};
  }

  handleColorChange(value) {
    this.$emit('change', value);
    this.$emit('input', value);
  }
}
</script>
<style lang="less" module>
.picker {
  width: 285px;
  padding: 10px;
  background: var(--dropdown-bg);
  border-radius: 4px;
}
.button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}
</style>
