var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.view)?_c('div',{class:_vm.$style['x-color-panel']},[_c('div',{class:[
      !_vm.value ? _vm.$style['x-color-panel--mosaic'] : '',
      _vm.$style['x-color-panel--block'],
      ( _obj = {}, _obj[_vm.$style['x-color-panel--disabled']] = _vm.view, _obj ) ],style:({
      background: _vm.value,
      color: !!_vm.value ? '#FFFFFF' : 'var(--primary-light)',
    })})]):_c('div',{class:_vm.$style['x-color-panel']},[_vm._l((_vm.colors),function(color){return _c('div',{key:color,class:[
      !color ? _vm.$style['x-color-panel--mosaic'] : '',
      _vm.$style['x-color-panel--block'],
      'x-color-panel--default-color' ],style:({
      background: color,
      color: !!color ? '#FFFFFF' : 'var(--primary-light)',
    }),on:{"click":function($event){return _vm.handleSelectColor(color)}}},[(color === _vm.selectedColor)?_c('x-icon',{class:_vm.$style['x-icon'],attrs:{"type":"tc-icon-check"}}):_vm._e()],1)}),(_vm.customizable)?_c('div',{class:_vm.$style['x-color-panel--block'],style:(("background: " + _vm.customColor))},[(!_vm.alpha)?_c('a-input',{class:_vm.$style['x-color-panel--input'],attrs:{"type":"color"},on:{"blur":_vm.handleColorChange,"click":function($event){$event.stopPropagation();return (function (event) { return event.stopPropagation(); }).apply(null, arguments)}},model:{value:(_vm.customColor),callback:function ($$v) {_vm.customColor=$$v},expression:"customColor"}}):_c('x-custom-color',{class:_vm.$style['x-color-panel--input'],on:{"change":_vm.handleColorChange,"click":function($event){$event.stopPropagation();return (function (event) { return event.stopPropagation(); }).apply(null, arguments)}},model:{value:(_vm.customColor),callback:function ($$v) {_vm.customColor=$$v},expression:"customColor"}}),(!_vm.customColor)?_c('x-icon',{class:_vm.$style['x-icon-add'],attrs:{"type":"plus-circle"}}):_vm._e()],1):_vm._e(),_vm._l((_vm.customColorList),function(color){return _c('div',{key:color,class:[
      !color ? _vm.$style['x-color-panel--mosaic'] : '',
      _vm.$style['x-color-panel--block'] ],style:({
      background: color,
      color: !!color ? '#FFFFFF' : 'var(--primary-light)',
    }),on:{"click":function($event){return _vm.handleSelectColor(color)}}},[(color === _vm.selectedColor)?_c('x-icon',{class:_vm.$style['x-icon'],attrs:{"type":"tc-icon-check"}}):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }