<template>
  <div
    :class="$style.mapWrap"
    :style="{
      width: $store.state.home.bigScreen ? '100vw' : '100%',
      height: $store.state.home.bigScreen ? '100vh' : '450px',
      position: $store.state.home.bigScreen ? 'fixed' : '',
      top: 0,
      left: 0,
      zIndex: 9999,
    }"
  >
    <div :class="$style.screen" style="z-index: 9999999">
      <x-icon
        :type="
          $store.state.home.bigScreen
            ? 'tc-icon-fullscreen-exit'
            : 'tc-icon-fullscreen'
        "
        :class="$style.icon"
        @click="handleScreen"
      />
    </div>
    <div
      id="boxMap"
      :class="$style.boxMap"
      :style="{
        width: $store.state.home.bigScreen ? '100vw' : '100%',
        height: $store.state.home.bigScreen ? '100vh' : '430px',
      }"
    ></div>
    <div :class="$style.topPart" ref="topPart">
      <a-select
        style="width: 105px"
        :placeholder="$t('hat.clockRecord.electronicFence')"
        allow-clear
        @change="handleSelectFence"
      >
        <a-select-option
          v-for="item in fenceList"
          :key="item.geofenceId"
          :value="item.geofenceId"
        >
          {{ item.geofenceName }}
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getFenceListById } from '@/services/smart-hat/geofence';
import { getZonesData } from '@/services/smart-hat/geofence';
import {
  drawCharts,
  formatToApi,
  funAMap,
  mouseEvent,
} from '../electron-fence/components/util';
@Component()
export default class FileMap extends Vue {
  mounted() {
    this.addMap();
    this.getFenceList();
  }

  beforeDestroy() {
    this.$store.commit('home/setBigScreen', false);
  }

  handleScreen() {
    const bigScreen = this.$store.state.home.bigScreen;
    this.$store.commit('home/setBigScreen', !bigScreen);
  }

  @Prop() record;
  fenceList = [];
  async getFenceList() {
    this.fenceList = await getFenceListById({
      deviceGroupIds: this.record.deviceId,
    });
  }

  @Prop() center;
  map = null;
  AMap = null;
  async addMap() {
    const AMap = await funAMap({
      AMapUI: {
        version: '1.1',
        plugins: [],
      },
    });
    this.AMap = AMap;
    const { mode } = this.$store.state.crossStorage.skin;
    this.map = new AMap.Map('boxMap', {
      zoom: 15,
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    });
    this.map.setZoomAndCenter(16, [this.center.lng, this.center.lat]);
    this.map.on('complete', () => {
      const marker = this.createMarker([this.center.lng, this.center.lat]);
      this.map.add(marker);
    });
  }

  createMarker(position) {
    return new this.AMap.Marker({
      position,
      draggable: false,
      zIndex: 8,
    });
  }

  handleSelectFence(id) {
    this.removeFences();
    if (id) {
      this.handleFenceChange(id, this.fenceList);
    }
  }

  zoneArray = [];
  cacheList = [];
  async handleFenceChange(id, listFence) {
    const item = listFence.find(v => v.geofenceId === id);
    if (item.point && item.point.lng) {
      this.map.setCenter([item.point.lng, item.point.lat]);
    }
    const array = await getZonesData({
      geofenceId: id,
    });
    this.zoneArray = formatToApi(array);
    const list = drawCharts(this.zoneArray, this.AMap, (a, b) =>
      mouseEvent(a, b, this.map),
    );
    this.cacheList = list;
    this.map.add(list);
  }

  removeFences() {
    this.map.remove(this.cacheList);
  }
}
</script>
<style lang="less" module>
.mapWrap {
  position: relative;
  height: 100%;
  width: 100%;
  :global {
    .ant-select {
      background-color: var(--main-bg);
      border-radius: 4px;
    }
  }
  .screen {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99;
  }
  .icon {
    font-size: 30px;
    color: var(--primary);
  }
  .boxMap {
    height: 450px;
  }
  .topPart {
    position: absolute;
    z-index: 9999;
    top: 10px;
    left: 10px;
    display: flex;
  }
}
</style>
