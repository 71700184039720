var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.mapWrap,style:({
    width: _vm.$store.state.home.bigScreen ? '100vw' : '100%',
    height: _vm.$store.state.home.bigScreen ? '100vh' : '450px',
    position: _vm.$store.state.home.bigScreen ? 'fixed' : '',
    top: 0,
    left: 0,
    zIndex: 9999,
  })},[_c('div',{class:_vm.$style.screen,staticStyle:{"z-index":"9999999"}},[_c('x-icon',{class:_vm.$style.icon,attrs:{"type":_vm.$store.state.home.bigScreen
          ? 'tc-icon-fullscreen-exit'
          : 'tc-icon-fullscreen'},on:{"click":_vm.handleScreen}})],1),_c('div',{class:_vm.$style.boxMap,style:({
      width: _vm.$store.state.home.bigScreen ? '100vw' : '100%',
      height: _vm.$store.state.home.bigScreen ? '100vh' : '430px',
    }),attrs:{"id":"boxMap"}}),_c('div',{ref:"topPart",class:_vm.$style.topPart},[_c('a-select',{staticStyle:{"width":"105px"},attrs:{"placeholder":_vm.$t('hat.clockRecord.electronicFence'),"allow-clear":""},on:{"change":_vm.handleSelectFence}},_vm._l((_vm.fenceList),function(item){return _c('a-select-option',{key:item.geofenceId,attrs:{"value":item.geofenceId}},[_vm._v(" "+_vm._s(item.geofenceName)+" ")])}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }